import RestaurantService from "../api/restaurant-service";
import DataService from "../api/basic-data-service";
import User from "../api/user";
import Member from "../api/member";
import Location from "../api/location"
import { buildOptions, getOption, getOptionValue, resetImageUrl } from "../utils";
import { build } from "../utils/pageable";
import { useRoute } from "vue-router";
import { Dialog } from 'vant';
import { userLoginTimeOutInit } from "../api/user-timeout";
import SystemConfigService from "../api/system-config";

const REC_MODE = 1, FILTER_MODE = 2, SEARCH_MODE = 3;

const retrieveRestaurants = (state, mode) => build(mode)
    .next((key, number, size) => retrieveNextRestaurants(state, key, number, size))
    .then(results => {
        state.restaurants = results;
        return results;
    })
const retrieveNextRestaurants = (state, key, number, size) => {
    if (key == REC_MODE) {
        return RestaurantService.getRecommendRestaurants(number, size);
    } else if (key == FILTER_MODE || key == SEARCH_MODE) {
        return RestaurantService.searchRestaurants(state.keyword, state.cityId, state.styleId, state.priceId, number, size)
    }
}
const searchRestaurants = state => retrieveRestaurants(state, state.keyword ? SEARCH_MODE : REC_MODE);
const filterRestaurants = state => retrieveRestaurants(state, FILTER_MODE);
const pullRefreshRestaurants = state => retrieveRestaurants(state);
const onLoad = state => {
    const level = "t2"
    retrieveRestaurants(state, REC_MODE).then(() => state.loading = false);
    DataService.getCities(level).then(results => {
        state.cities = buildOptions(results, "城市", 0, c => ({ value: c.id, text: c.name }));
        state.loading = false;
        return Location.getLocation();
    }).then(result => {
        if (result.first) {
            Dialog.confirm({ message: '是否允许访问您的位置。' })
                .then(() => {
                    Location.setAllowLocation(true);
                    state.cityId = getOptionValue(state.cities, result.city)
                    filterRestaurants(state);
                }).catch(() => {
                    Location.setAllowLocation(false);
                });
        } else {
            if (Location.getAllowLocation() == 'true') {
                state.cityId = getOptionValue(state.cities, result.city)
                filterRestaurants(state);
            }
        }
    });
    DataService.getCookingStyles(level).then(results => {
        state.cookingStyles = buildOptions(results, "菜系", 0, c => ({ value: c.id, text: c.name }));
        state.loading = false;
    });
    DataService.getPrices(level).then(results => {
        state.prices = buildOptions(results, "人均", 0, c => {
            let opt = {
                value: c.id,
                text: c.min + "-" + c.max
            };
            if(!c.max) {
                opt.text = ">" + c.min;
            }
            return opt;
        });
        state.loading = false;
    });
}
const onEpidemic = state => {
    SystemConfigService.isEnableEpidemic().then(result => {
        state.enableEpidemic = (result.msg || 'false' ) == 'true';
        if( state.enableEpidemic ) {
            return SystemConfigService.getEpidemicRestContent();
        }
    }).then(result => state.epidemicContent = (result ? result.msg : "") || "")
}
const onMounted = (state, router) => {
    const { code, client, cardProductName, cardLevel, cardBin, cardId, userId, jumpUrl} = useRoute().query;
    const data = { code, client, cardProductName, cardLevel, cardBin, cardId, userId, jumpUrl};
    User.login(data)
        .then(() => Member.getMemberInfo(), () => User.notFound(router))
        .then(() => {
            userLoginTimeOutInit(data);
            onLoad(state);
            onEpidemic(state);
        }, () => User.notFound(router));
}

export default {
    searchRestaurants,
    filterRestaurants,
    pullRefreshRestaurants,
    getOption,
    resetImageUrl,
    onMounted
}