<template>
  <div class="index restaurant">

    <van-notice-bar
      v-if="enableEpidemic"
      mode="closeable"
      wrapable
      :scrollable="false"
      :text="epidemicContent"
    />

    <header>
      <van-search
        class="restaurant-search"
        v-model="keyword"
        placeholder="搜索餐厅"
        @search="onSearch"
      />
    </header>


    <van-skeleton title :row="2" :loading="loading" >
      <van-dropdown-menu class="restaurant-filter">
        <van-dropdown-item
          v-model="cityId"
          :options="cities"
          @change="onFilter"
        >
          <template #title>
              <van-icon name="location-o" color="#333" /> {{getOption(cities , cityId)}}
          </template>
        </van-dropdown-item>
        <van-dropdown-item
          v-model="styleId"
          :options="cookingStyles"
          @change="onFilter">
          <template #title>
            <van-icon name="fire-o" color="#333" /> {{getOption(cookingStyles , styleId)}}
          </template>
        </van-dropdown-item>
        <van-dropdown-item
          v-model="priceId"
          :options="prices"
          @change="onFilter">
          <template #title>
            <van-icon name="gold-coin-o" color="#333" /> {{getOption(prices , priceId)}}
          </template>
        </van-dropdown-item>
      </van-dropdown-menu>
    </van-skeleton>
    <!-- <van-skeleton title :row="6" :loading="loading" > -->
    <van-list @load="onPullRefresh">
      <section class="rest-card" v-for="rest in restaurants" :key="rest.id">
        <div class="rest-header">
          <div>
            <div class="rest-name">{{ rest.name }}</div>
            <div class="rest-info">
              <span>{{rest.cookingStyle}}</span>
              <span>¥{{rest.pricePerPerson}}/人</span>
              <span>{{rest.cityName}}</span>
              <!-- <template v-if="rest.dishs.length > 0">
                <span>{{ rest.dishs.length }}道折扣招牌菜</span>
              </template> -->
            </div>
          </div>
          <van-button class="btn-order" type="primary" @click="onBooking(rest.id)">预定</van-button>
        </div>
        <van-image width="100%" height="160px" fit="cover" 
          :src="resetImageUrl(rest.imageUrl,0,0)" 
          @click="onRestaurantDetails(rest.id)"
          lazy-load  
        />

        <van-row gutter="10">
          <van-col span="8" v-for="(dish,index) in rest.dishs" :key="dish.id">
            <div class="dish-card" v-if="index < 3">
              <van-image
                width="100%"
                height="70px"
                fit="cover"
                lazy-load
                :src="resetImageUrl(dish.imageUrl,0,0)"
              />
              <div class="price-row">
                <span>￥{{dish.price}}</span>
                <span>原价:￥{{dish.originPrice}}</span>
              </div>
              <div class="dish-name" :title="dish.name">{{ resetDishName(dish.name) }}</div>
            </div>
          </van-col>
        </van-row>
      </section>
    </van-list>
    <!-- </van-skeleton> -->
  </div>
  <Nav active="restaurant"></Nav>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter} from "vue-router";
import Nav from "../components/Nav.vue"
import Controller from "./restaurant-controller";
import "../css/restaurant.scss";

export default {
  components: {
    Nav
  },
  setup() {
    const state = reactive({
      restaurants: [],
      cityId: 0,
      cities:[],
      styleId: 0,
      cookingStyles: [],
      priceId: 0,
      prices: [],
      keyword: "",
      loading: true,
      count: 0,
      pullRefresh: false,
      enableEpidemic: false,
      epidemicContent: ""
    });
    const router = useRouter();
    const onRestaurantDetails = (id) => router.push("/restaurant/" + id);
    const onBooking = id => router.push("/restaurant/" + id + "/booking");
    const onSearch = () => Controller.searchRestaurants(state);
    const onFilter = () => Controller.filterRestaurants(state);
    const getOption = (values , id) => Controller.getOption(values ,id);
    const resetDishName = (name) => name.length > 6 ? name.substring(0, 6) + "..." : name;
    const onPullRefresh = () => {
        if( state.pullRefresh ) {  //skip
          return ;
        }
        state.pullRefresh = true;
        Controller.pullRefreshRestaurants(state)
          .then(() => state.pullRefresh = false);
    }


    onMounted(() => Controller.onMounted(state , router));
    return {
      ...toRefs(state),
      onRestaurantDetails,
      onSearch,
      onFilter,
      onBooking,
      getOption,
      resetImageUrl: Controller.resetImageUrl,
      onPullRefresh,
      resetDishName
    };
  },
};
</script>
